<template>
  <div class="container-fluid">
    <Header
      v-if="state.loaded"
      header="Home"
      title="Pengumuman"
      :state="state"
      :addLink="goToAddView"
      :forbidden="forbid"
    />

    <router-view/>
  </div>
</template>

<script>
import Header from "@/components/pageTitle/index";

export default {
  components: {
    Header
  },
  computed: {
    state() {
      return this.$store.state.information;
    },
    forbid() {
      if (this.$store.state.profile.permissions.information) {
        return !this.$store.state.profile.permissions.information.create;
      }
    }
  },
  methods: {
    goToAddView() {
      this.$store.dispatch("information/onAdd");
    }
  }
};
</script>
<style>
.mt-20 {
  margin-top: 14rem !important;
  margin-bottom: 13rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
}
</style>
